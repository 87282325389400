import { useRef, useEffect, useState } from "react";
import "./Footer.css";
import myAxios from "../AxiosHeader";
import { URL } from "../AxiosUrlGlobal";

function Footer() {
  const [store, setStore] = useState();
  useEffect(() => {
    myAxios
      .get(`${URL}/e-store`)
      .then((res) => {
        setStore(res.data.domain);
      })
      .catch((err) => console.log(err.response.data));
  }, []);
  const d = new Date();
  let year = d.getFullYear();
  return (
    <>
      <div className="footer">
        <div className="deDesign-and-Developeds">
          Copyright {year} ©{" "}
          <a href={`https://${store ? store : ""}`} target="blank">
            {store ? store : ""}
          </a>{" "}
        </div>
      </div>
      <div className="footer-credit">
        <div style={{ fontSize: "12px", textAlign: "center", padding: "5px" }}>
          Design and Developed by{" "}
          <a href="https://www.facebook.com/ruhul247" target="blank">
            Ruhul Amin
          </a>{" "}
          Using React Js, Node Js, MongoDB, Express, Redis
        </div>
      </div>
    </>
  );
}

export default Footer;
