import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import Header from "./Components/Header/Header";
import Category from "./Components/Category/Category";
import Invest from "./Components/Invest/Invest";
import Products from "./Components/Products/Products";
import ProductsNew from "./Components/ProductsNew/ProductsNew";
import CheckOut from "./Components/CheckOut/CheckOut";
import NotFound from "./Components/NotFound/NotFound";
import ViewCategory from "./Components/ViewCategory/ViewCategory";
import Footer from "./Components/Footer/Footer";
import Home from "./Components/Home/Home";
function App() {
  useEffect(() => {
    if (window.location.protocol !== "https:") {
      const httpsUrl = `https://${window.location.hostname}${window.location.pathname}`;
      window.location.replace(httpsUrl);
    }
  }, []);
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/index.html" element={<Home />} />
          <Route path="/products/:id/:fid?" element={<Products />} />
          <Route path="/product-new/:id/:fid?" element={<ProductsNew />} />
          <Route path="/view-category/:id" element={<ViewCategory />} />
          <Route path="/checkout" element={<CheckOut />} />
          <Route path="*" element={<NotFound />} />
          <Route path="Invest" element={<Invest />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
