import React, { useEffect, useState } from "react";
// import { useLocation } from 'react-router-dom';
import Category from "../Category/Category";
import Offer from "../Offer/Offer";
import { Helmet } from "react-helmet";
import axios from "axios";
import { URL } from "../AxiosUrlGlobal";
// import Cookies from 'js-cookie';

function Home() {
  const [store, setStore] = useState();
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const heightData = screenSize.height <= 700 ? "100vh" : "100%";

  const updateScreenSize = () => {
    setScreenSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", updateScreenSize);
    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);

  useEffect(() => {
    axios
      .get(`${URL}/e-store`)
      .then((res) => setStore(res.data))
      .catch((err) => console.log(err.response.data));
  }, []);

  // useEffect(()=>{
  //   const fbp = Cookies.get('_fbp') || Cookies.get('_fbc');
  //   const params = new URLSearchParams(location.search);
  //   const fbc = params.get('fbc');
  //   if(fbc){
  //     axios
  //     .post(`${URL}/e-store`, { fbp, fbc})
  //     .then((res) => setStore(res.data))
  //     .catch((err) => console.log(err.response.data));
  //   }
  // },[])

  return (
    <div style={{ height: heightData }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{store?.domain ? store.domain.toUpperCase() : ""} || HOME</title>
      </Helmet>
      <Category />
      <Offer />
    </div>
  );
}

export default Home;
