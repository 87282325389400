import React, { useEffect, useState, useContext } from "react";
import { Cartcontext } from "../../context/Context";
import "./Offer.css";
import offer from "../../image/hot-deal-logo.gif";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import { motion } from "framer-motion";
import axios from "axios";
import { URL } from "../AxiosUrlGlobal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Offer() {
  const navigate = useNavigate();
  const [loadCategory, setLoadCategory] = useState(true);
  const [pageNumber, setPage] = useState(1);
  const [product, setProduct] = useState([]);
  const [nocategory, setNocategory] = useState(false);
  const Globalstate = useContext(Cartcontext);
  const state = Globalstate.state;
  const dispatch = Globalstate.dispatch;
  const [Totalpage, setTotalPage] = useState(1);

  const PageNumber = (e, p) => {
    setPage(p);
  };

  useEffect(() => {
    if (product.length === 0 && !loadCategory) {
      setNocategory(true);
    }
  }, [loadCategory]);

  useEffect(() => {
    axios
      .get(`${URL}/e-products`)
      .then((res) => {
        setProduct(res.data);
        setLoadCategory(false);
      })
      .catch((err) => console.log(err));
  }, [pageNumber]);

  const AddtoCart = (data) => {
    if (
      data._id === "6420774a076c3e2bdd58f2ae" ||
      data._id === "64207958076c3e2bdd58f2d9" ||
      data._id === "642b45e176572f46724dfe71" ||
      data._id === "64207fa2076c3e2bdd58f3e8"
    ) {
      navigate(`/product-new/64540211e776cdd487628b64`);
      return;
    }

    if (data.productPage === "2") {
      navigate(`/product-new/${data._id}`);
      return;
    }

    if (data.quantity <= 0) {
      return toast.error("Out Of Stock");
    }
    if (data.variationData.length > 0) {
      return navigate(`/products/${data._id}`);
    }
    state.map((context) => {
      if (context.gid === data._id) {
        toast.success("Already added to cart");
      }
    });
    const checkoutData = {
      id: data._id,
      title: data.productName,
      colorName: data.color,
      image: data.imgeUrl,
      price: data.proceSell,
      quantity: 1,
      VariationType: data.variation,
      gid: data._id,
      pCode: data.pCode,
    };
    dispatch({ type: "ADD", payload: checkoutData });
  };

  const BuyNow = (data) => {
    if (
      data._id === "6420774a076c3e2bdd58f2ae" ||
      data._id === "64207958076c3e2bdd58f2d9" ||
      data._id === "642b45e176572f46724dfe71" ||
      data._id === "64207fa2076c3e2bdd58f3e8"
    ) {
      navigate(`/product-new/64540211e776cdd487628b64`);
      return;
    }

    if (data.productPage === "2") {
      navigate(`/product-new/${data._id}`);
      return;
    }

    if (data.quantity <= 0) {
      return toast.error("Out Of Stock");
    }
    if (data.variationData.length > 0) {
      return navigate(`/products/${data._id}`);
    }
    const checkoutData = {
      id: data._id,
      title: data.productName,
      colorName: data.color,
      image: data.imgeUrl,
      price: data.proceSell,
      quantity: 1,
      VariationType: data.variation,
      gid: data._id,
      pCode: data.pCode,
    };
    dispatch({ type: "ADD", payload: checkoutData });
    navigate(`/checkout`);
  };
  return (
    <>
      <ToastContainer />
      {loadCategory && (
        <div className="category-loading">
          <div className="category-loading-in">
            <ReactLoading
              type="cylon"
              color="rgb(226,115,29)"
              height={60}
              width={60}
            />
          </div>
        </div>
      )}
      {nocategory && <div className="no-category">No Product Found..</div>}
      {product.length && (
        <div className="Offer-Main">
          <div className="offer-deal">
            <img style={{ width: "120px" }} src={offer} alt="Deals" />
            <h2>{`সকল হট ডিল >`}</h2>
          </div>

          <div className="Offer-Container">
            {product &&
              product.map((data, index) => {
                const title = data.productName.substring(0, 15) + "...";
                return (
                  <motion.div
                    className="Offer-Product"
                    key={index}
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.7 }}
                  >
                    <img
                      src={data.imgeUrl}
                      alt={data.productName}
                      onClick={() => {
                        if (
                          data._id === "6420774a076c3e2bdd58f2ae" ||
                          data._id === "64207958076c3e2bdd58f2d9" ||
                          data._id === "642b45e176572f46724dfe71" ||
                          data._id === "64207fa2076c3e2bdd58f3e8"
                        ) {
                          navigate(`/product-new/64540211e776cdd487628b64`);
                          return;
                        }
                        if (data.productPage === "2") {
                          navigate(`/product-new/${data._id}`);
                          return;
                        }
                        navigate(`/products/${data._id}`);
                      }}
                    />

                    <div className="sale-out">
                      {data.quantity <= 0 ? "Out Of Stock" : "Sale"}
                    </div>
                    <div className="price-flex">
                      <p className="price-line">৳ {data.compare}</p>
                      <p className="price">৳ {data.proceSell}</p>
                    </div>

                    <h2
                      onClick={() => {
                        if (
                          data._id === "6420774a076c3e2bdd58f2ae" ||
                          data._id === "64207958076c3e2bdd58f2d9" ||
                          data._id === "642b45e176572f46724dfe71" ||
                          data._id === "64207fa2076c3e2bdd58f3e8"
                        ) {
                          navigate(`/product-new/64540211e776cdd487628b64`);
                          return;
                        }
                        if (data.productPage === "2") {
                          navigate(`/product-new/${data._id}`);
                          return;
                        }
                        navigate(`/products/${data._id}`);
                      }}
                    >
                      {title}
                    </h2>
                    <button
                      className="offer-addtocart"
                      onClick={() => AddtoCart(data)}
                    >
                      কার্ট-এ যোগ করুন
                    </button>
                    <button
                      className="offer-buynow"
                      onClick={() => BuyNow(data)}
                    >
                      অর্ডার করুন
                    </button>
                  </motion.div>
                );
              })}
          </div>
          {/* {product.length > 9 && (
            <div className="Offer-Pagination">
              <Stack spacing={2}>
                <Pagination
                  count={Totalpage}
                  color="primary"
                  onChange={PageNumber}
                />
              </Stack>
            </div>
          )} */}
        </div>
      )}
    </>
  );
}

export default Offer;
