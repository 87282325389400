import { createContext, useEffect, useReducer } from "react";

export const Cartcontext = createContext();
export const Context = (props) => {
  useEffect(() => {
    localStorage.setItem("cartData", JSON.stringify(state));
  });
  const reducer = (state, action) => {
    switch (action.type) {
      case "ADD":
        const tempstate = state.filter(
          (item) => action.payload.gid === item.gid
        );
        if (tempstate.length > 0) {
          return state;
        } else {
          return [...state, action.payload];
        }

      case "INCREASE":
        const tempstate1 = state.map((item) => {
          if (item.gid === action.payload.gid) {
            return { ...item, quantity: item.quantity + 1 };
          } else {
            return item;
          }
        });
        return tempstate1;

      case "DECREASE":
        const tempstate2 = state.map((item) => {
          if (item.gid === action.payload.gid) {
            return { ...item, quantity: item.quantity - 1 };
          } else {
            return item;
          }
        });
        return tempstate2;

      case "RESET":
        return (state = []);

      case "REMOVE":
        const tempstate3 = state.filter(
          (item) => item.gid !== action.payload.gid
        );

        return tempstate3;

      default:
        return state;
    }
  };
  let data;
  if (JSON.parse(localStorage.getItem("cartData"))) {
    data = JSON.parse(localStorage.getItem("cartData"));
  } else {
    data = [];
  }
  const [state, dispatch] = useReducer(reducer, data);
  const info = { state, dispatch };
  return (
    <Cartcontext.Provider value={info}>{props.children}</Cartcontext.Provider>
  );
};
