import React from "react";
import "./NotFound.css";

function NotFound() {
  return (
    <div className="not-found-main">
      <h2>404</h2>{" "}
    </div>
  );
}

export default NotFound;
