import React, { useEffect, useState, useContext } from "react";
import { Cartcontext } from "../../context/Context";
import "./CartTop.css";
import { BsFillCartCheckFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

function CartTop() {
  const navigate = useNavigate();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [shake, setShake] = useState(false);

  const Globalstate = useContext(Cartcontext);
  const state = Globalstate.state;

  const total = state.reduce((total, item) => {
    return total + item.price * item.quantity;
  }, 0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
  }, []);

  useEffect(() => {
    if (total) {
      setShake(true);
      setTimeout(() => setShake(false), 2000);
    }
  }, [total]);
  return (
    <>
      {state.length ? (
        <div
          className={scrollPosition >= 87 ? "CartTopa" : "CartTop"}
          onClick={() => {
            navigate("/checkout");
          }}
        >
          <div style={{ position: "relative" }}>
            <BsFillCartCheckFill />
            <span className="cart-item-span">{state.length}</span>
          </div>
          <div>৳ {total}</div>
          <button
            onClick={() => {
              navigate("/checkout");
            }}
            className={shake ? `shake` : "shake-"}
          >
            Check Out
          </button>
        </div>
      ) : null}
    </>
  );
}

export default CartTop;
