import React, { useState } from "react";
import "./Invest.css";
import AnimatedInput from "../AnimatedInput/AnimatedInput";
import AnimatedInput1 from "../AnimatedInput/AnimatedInput1";
import axios from "axios";
import { URL } from "../AxiosUrlGlobal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Invest() {
  const [name, setName] = useState();
  const [mobile, setMobile] = useState();
  const [amount, setAmount] = useState();
  const [loading, setLoadIng] = useState(false);
  const [showProduct, setShowProduct] = useState(false)
  const submit = () => {
    setLoadIng(true);
    if ((!name, !mobile, !amount)) {
      toast.error("Name, Mobile, Amount is Required.");
      setLoadIng(false);
      return;
    }
    const data = {
      name,
      mobile,
      amount,
    };
    axios
      .post(`${URL}/e-invest`, data)
      .then((res) => {
        toast.success(res.data);
        setShowProduct(true)
        setLoadIng(false);
        setName("");
        setMobile("");
        setAmount("");
      })
      .catch((err) => console.log(err.response.data));
  };
  return (
    <div className="invest-main">
      <ToastContainer />
     {!showProduct && <div className="invest">
        রুসু বিডি একটি ইকমার্স সাইট আমরা মেয়েদের জুয়েলারি এবং ফ্যাশন আইটেম সেল
        করি যেগুলো আমরা চায়না থেকে নিয়ে আসি | আমাদের কাছে বিনিয়োগ করে প্রতি লাখে
        ২০০০ থেকে ৩০০০ টাকা ইনকাম করুন মাসে | সর্বনিম্ন ১০০০০০ বিনিয়োগ করতে
        পারবেন
      </div>} 
      {showProduct &&
        <div className="see-pro">
          <button className="see-product">
            <a href="https://rusubd.com">
             আমাদের প্রোডাক্ট গুল দেখুন
            </a>
          </button>
        </div>
      }
      {!showProduct &&
        <div>
          <div className="mobile-number-checkout">
            <div className="invest">
              আপনার নাম, মোবাইল ও কত টাকা বিনিয়োগ করবেন নিচে লিখুন আমরা আপনাকে
              কল দিব
            </div>
            <AnimatedInput
              type="text"
              className="mobile-checkout"
              name="name"
              placeholder="এখানে আপনার নাম লিখুন"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <AnimatedInput
              type="number"
              className="mobile-checkout"
              name="mobile"
              placeholder="১১ সংখ্যার মোবাইল নম্বর লিখুন"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
            <AnimatedInput1
              className="mobile-checkout"
              type="text"
              name="address"
              placeholder="কত টাকা বিনিয়োগ করতে চান লিখুন"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>
          <button className="call-button-checkout" onClick={submit}>
            {loading ? "Loading.." : "সেন্ড করুন"}
          </button>
        </div>
      }
    </div>
  );
}

export default Invest;
