import React, { useState, useContext } from "react";
import "./Modal.css";
import { useNavigate } from "react-router-dom";

import { Cartcontext } from "../../context/Context";
function Modal({ setModal, data }) {
  const navigate = useNavigate();
  const Globalstate = useContext(Cartcontext);
  const state = Globalstate.state;
  const dispatch = Globalstate.dispatch;

  const total = state.reduce((total, item) => {
    return total + item.price * item.quantity;
  }, 0);

  const [mobileQuantity, setMobileQuantity] = useState(1);
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        {/* <p
          className="flicker"
          // style={{
          //   textAlign: "center",
          //   fontSize: "13px",
          //   backgroundColor: "rgb(255, 181, 181)",
          //   marginBottom: "10px",
          //   padding: "5px",
          // }}
        >
          {total >= 3000
            ? `অভিনন্দন ফ্রী ডেলিভারি অ্যাড হয়েছে !`
            : `${total} টাকা অ্যাড হয়েছে আর মাত্র ${
                1400 - total
              } টাকা হলে ফ্রী ডেলিভারি !`}
        </p> */}
        <img
          className="modalProductImg"
          height={140}
          width={140}
          src={data.image}
        />
        <p
          style={{ textAlign: "center", marginBottom: "10px" }}
        >{`একটির বেশি নিতে চাইলে নিচে + এ ক্লিক করুন `}</p>
        <div className="count">
          <div
            className="count-neg"
            onClick={() => {
              if (mobileQuantity > 1) {
                dispatch({ type: "DECREASE", payload: data });
                setMobileQuantity(mobileQuantity - 1);
              } else {
                return;
              }
            }}
          >
            -
          </div>
          <div className="count-number">{mobileQuantity}</div>
          <div
            className="count-pos"
            onClick={() => {
              dispatch({ type: "INCREASE", payload: data });
              setMobileQuantity(mobileQuantity + 1);
            }}
          >
            +
          </div>
        </div>
        <div className="title">
          যদি আরও প্রোডাক্ট কিনতে চান তাহলে আরও কিনব বাটন এ ক্লিক করুন অথবা
          Order Now! বাটন এ ক্লিক করে অর্ডারটি সম্পূর্ণ করুন
        </div>

        <div className="footer-modal">
          <button
            onClick={() => {
              navigate("/checkout");
            }}
            id="cancelBtn"
          >
            Order Now!{" "}
          </button>
          <button
            onClick={() => {
              setModal(false);
            }}
          >
            আরও কিনব
          </button>
        </div>
      </div>
    </div>
  );
}

export default Modal;
