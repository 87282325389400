import { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import "./Header.css";
import CartTop from "../CartTop/CartTop";
import Notification from "../Notification/Notification";
import axios from "axios";
import myAxios from "../AxiosHeader";
import { URL } from "../AxiosUrlGlobal";
import { useNavigate } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";

function Navbar() {
  const navigate = useNavigate();
  const [loadCategory, setLoadCategory] = useState(true);
  const [product, setProduct] = useState([]);
  const [zeroCategory, setZeroCategory] = useState(false);
  const [logoweb, setLogoweb] = useState();
  const [alt, setAlt] = useState();
  const navRef = useRef();
  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };
  const [store, setStore] = useState();
  useEffect(() => {
    if (product.length === 0 && !loadCategory) {
      setZeroCategory(true);
    }
  }, [loadCategory]);
  useEffect(() => {
    myAxios
      .get(`${URL}/e-store`)
      .then((res) => {
        setStore(res.data);
        setLogoweb(res.data.logo);
        setAlt(res.data.name);
        if (res.data.pixel) {
          // console.log("551379043713797");
          console.log(res.data.pixel);
          const options = {
            autoConfig: true,
            debug: false,
          };
          // ReactPixel.init("551379043713797", options);
          ReactPixel.init(res.data.pixel, options);
          ReactPixel.pageView();
        }
      })
      .catch((err) => console.log(err.response.data));
  }, []);
  useEffect(() => {
    axios
      .get(`${URL}/e-category`)
      .then((res) => {
        setProduct(res.data);
        setLoadCategory(false);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    if (!localStorage.getItem("vid")) {
      localStorage.setItem("vid", generateRandomId());
    }
  }, []);
  function generateRandomId() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomId = "";
    for (let i = 0; i < 50; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomId += characters.charAt(randomIndex);
    }
    return randomId;
  }
  useEffect(() => {
    console.log(window.location.href);
  }, []);
  return (
    <>
      <CartTop />
      {/* <Notification /> */}
      <header>
        <Link to={"/"} style={{ textDecoration: "none" }}>
          <div className="logoMain">
            <img
              className="h-logo"
              src={logoweb ? logoweb : ""}
              alt={alt ? alt : ""}
            />
          </div>

          {/* <h2 className="h-logo">{store?.store ? store.store : ""}</h2> */}
        </Link>
        <nav ref={navRef}>
          {zeroCategory && <div>No menue found..</div>}
          {product.map((data, index) => {
            return (
              <div key={index}>
                <a
                  onClick={() => {
                    showNavbar();
                    navigate(`/view-category/${data.id}`);
                  }}
                >
                  {data.name}
                </a>
              </div>
            );
          })}
          <button className="nav-btn nav-close-btn" onClick={showNavbar}>
            <FaTimes />
          </button>
        </nav>

        <button className="nav-btn" onClick={showNavbar}>
          <FaBars />
        </button>
      </header>
    </>
  );
}

export default Navbar;
